<template>
  <section class="tables" style="margin-top: -56px">
    <div class="row col-10">
      <div class="col-12 p-0">
        <p class="h2 m-0 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

          <span style="font-size: 18px" class="font-weight-light"
            >Patrimoine :
          </span>
          <span style="font-size: 22px">{{ libelle }}</span>
        </p>

        <p class="h2 m-0 text-secondary">
          <i style="font-size: 18px" :class="$route.meta.iconClass"></i>

          <span style="font-size: 16px" class="font-weight-light"
            >Composant :
          </span>
          <span style="font-size: 18px">{{ items.name }}</span>
        </p>
      </div>
    </div>
    <div class="row col-12">
      <div class="col-12 p-0 mt-3">
        <h4 class="card-title mb-1">Liste des capacités techniques :</h4>
        <hr />
      </div>
    </div>
    <div class="row" style="margin-top: -20px">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body p-0">
            <b-table
              :items="items.capacites"
              id="table-list"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :fields="tableFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="loading"
              show-empty
              bordered
              striped
            >
              <!-- FOR LOADING ANIMATIONS -->
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Chargement...</strong>
                </div>
              </template>
              <!-- SHOW IF TABLE IS EMPTY -->
              <template #empty>
                <div class="text-center text-gray">Table vide</div>
              </template>
              <template v-slot:cell(name)="data">
                <b-link
                  :to="{
                    name: 'show-technical-capacity',
                    params: {
                      id: data.item.id,
                      slug: data.item.slug || '620f6d5321c35',
                    },
                  }"
                  >{{ data.value }}</b-link
                >
              </template>

              <template #cell(niveau)="data">
                <span>{{ data.value ? data.value : "-" }}</span>
              </template>

              <template #cell(sousCapacites)="data">
                <div class="text-center">
                  <b-badge
                    v-if="data.value.length"
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    pill
                    >{{ uniqBy(data.value, "id").length || 0 }}</b-badge
                  >
                  <b-badge
                    v-else
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    pill
                    >{{ uniqBy(data.value, "id").length }}</b-badge
                  >
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import { uniqBy } from "lodash";
Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {},
  props: {
    items: Object,
    libelle: String,
  },

  data: function () {
    return {
      sortBy: "name",
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      tableFields: [
        { key: "name", label: "Capacité technique", sortable: true },
        {
          key: "sousCapacites",
          label: "Capacité de rattachement",
          sortable: true,
        },
        { key: "niveau", label: "Niveau", sortable: true },
      ],
      tableItems: [],
    };
  },
  methods: {
    uniqBy,
  },
  computed: {
    rows() {
      return this.items.capacites ? this.items.capacites.length : 0;
    },
  },
};
</script>
