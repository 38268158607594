<template>
  <div style="margin-top: -60px">
    <div class="row">
      <div class="col-10 col-md-10">
        <p class="h2 m-0 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

          <span style="font-size: 18px" class="font-weight-light"
            >Patrimoine :
          </span>
          <span style="font-size: 22px">{{ libelle }}</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12">
        <h4 class="card-title mt-4 mb-1">Liste des versions :</h4>
        <hr />
      </div>
    </div>
    <b-table
      :items="versions"
      :fields="tableFields"
      id="table-list"
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="loading"
      show-empty
      bordered
      striped
    >
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template v-slot:cell(status)="data">
        <b-badge variant="success" v-if="data.value === 'En Cours'">{{
          data.value
        }}</b-badge>
        <b-badge variant="danger" v-else-if="data.value === 'Obsolete'">{{
          data.value
        }}</b-badge>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    sortBy: "name",
    perPage: 4,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    loading: true,
    tableFields: [
      { key: "name", label: "Version", sortable: true },
      { key: "description", label: "Description", sortable: true },
      { key: "status", label: "Status version", sortable: true },
    ],
  }),
  created() {
    this.$store
      .dispatch("composant/fetchComposant", this.composant.id)
      .then(() => {
        this.loading = false;
      });
  },
  props: {
    composant: Object,
    libelle: String,
  },
  computed: {
    ...mapGetters("composant", ["VERSION"]),
    versions() {
      return this.VERSION.versions;
    },
    rows() {
      return this.VERSION.versions ? this.VERSION.versions.length : 0;
    },
  },
};
</script>
