<template>
  <div style="margin-top: -50px">
    <div class="row" style="margin-top: -56px">
      <div class="col-10">
        <p class="h2 m-0 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

          <span style="font-size: 18px" class="font-weight-light">
            Patrimoine :
          </span>
          <span style="font-size: 22px">{{ patrimoine }}</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h4 class="card-title mt-4 mb-1">
          Liste des systèmes d'applications :
          <span style="font-size: 22px">{{ libelle }}</span>
        </h4>

        <hr />
      </div>
    </div>

    <b-table
      responsive
      :items="tabledata"
      :fields="technicalCapacity.tableFields"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="loading"
      show-empty
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template v-slot:cell(type)="data">
        <span>{{ data.value }}</span>
      </template>
      <template v-slot:cell(systeme)="data">
        <b-link
          :to="{
            name:
              data.item.type == 'Composant Technique'
                ? 'show-composant'
                : 'show-application',
            params: {
              id: data.item.id,
              slug: data.item.slug || 'abcde',
            },
          }"
        >
          {{ data.value }}
        </b-link>
      </template>
      <template v-slot:cell(po)="data">
        <TableResponsable
          v-if="!data.item.hasOwnProperty('editMode')"
          :responsable="data.value"
        ></TableResponsable>
      </template>
      <template v-slot:cell(capacite)="data">
        <b-link
          :to="
            data.value
              ? {
                  name: 'show-technical-capacity',
                  params: {
                    id: data.value.id || '1',
                    slug: data.value.slug || '621dd50372819',
                  },
                }
              : false
          "
          >{{ data.value ? (data.value.name ? data.value.name : "-") : "-" }}
        </b-link>
      </template>
      <template v-slot:cell(taux)="data">
        <b-progress
          max="100"
          show-progress
          height="15px"
          style="background-color: #cacccd !important"
        >
          <b-progress-bar
            :value="data.value || 0"
            :label="`${data.value || 0}%`"
            :style="`background-color: ${
              data.value > 20 && data.value <= 70
                ? '#f5f245'
                : data.value > 70
                ? '#23d92c'
                : '#f5ba45'
            }`"
          ></b-progress-bar>
        </b-progress>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import { IMG_URL } from "@/helpers/services/api";
import TableResponsable from "@/components/custom/TableResponsable";
import { mapGetters } from "vuex";
import { concat } from "lodash";

export default {
  components: { TableResponsable },
  data() {
    return {
      IMG_URL,
      sortBy: "id",
      perPage: 4,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      tabledata: null,
      loading: true,
      technicalCapacity: {
        tableFields: [
          { key: "type", label: "Type système", sortable: true },
          { key: "systeme", label: "Système", sortable: true },
          { key: "po", label: "PO", sortable: true },
          { key: "capacite", label: "Capacité", sortable: true },
          { key: "taux", label: "Taux de remplissage", sortable: true },
        ],
      },
    };
  },

  created() {
    this.$store.dispatch("cadre/fetchComposant", 2).then(() => {
      this.$store.dispatch("cadre/fetchApplication", 2).then(() => {
        this.loading = false;
      });
    });
  },
  computed: {
    ...mapGetters("cadre", ["COMPOSANT"]),
    ...mapGetters("cadre", ["APPLICATION"]),

    rows() {
      return this.tabledata ? this.tabledata.length : 0;
    },
  },
  methods: {},
  props: {
    items: Array,
    libelle: String,
    patrimoine: String,
  },
  watch: {
    APPLICATION() {
      var app = this.APPLICATION.applications
        ? this.APPLICATION.applications.map((data) => ({
            ...data,
            type: "Application",
            systeme: data.name,
            capacite: data.capaciteSis
              ? data.capaciteSis.length == 1
                ? data.capaciteSis[0]
                : data.capaciteSis
                    .filter((data) => data.parent)
                    .map((data) => data.parent)[0]
              : "-",
          }))
        : [];
      var composant = this.COMPOSANT
        ? this.COMPOSANT.composants.map((data) => ({
            ...data,
            type: "Composant Technique",
            systeme: data.name,
            po: data.archictecteTechnique,
            capacite: data.capacites
              ? data.capacites.length == 1
                ? data.capacites[0]
                : data.capacites
                    .filter((data) => data.parent)
                    .map((data) => data.parent)[0]
              : "-",
          }))
        : [];

      this.tabledata = concat(app, composant);
    },
  },
};
</script>

<style></style>
