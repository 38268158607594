<template>
  <div>
    <div class="row" style="margin-top: -56px">
      <div class="col-10">
        <p class="h2 m-0 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

          <span style="font-size: 18px" class="font-weight-light">
            Patrimoine :
          </span>
          <span style="font-size: 22px">{{ patrimoine }}</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h4 class="card-title mt-4 mb-1">
          Liste des capacités :
          <span style="font-size: 22px">{{ libelle }}</span>
        </h4>

        <hr />
      </div>
    </div>
    <b-table
      responsive
      :items="items"
      :fields="technicalCapacity.tableFields"
      :busy="loading"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-empty
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template v-slot:cell(type)="data">
        <span>{{ data.value }}</span>
      </template>
      <template v-slot:cell(name)="data">
        <b-link
          :to="{
            name: 'show-technical-capacity',
            params: {
              id: data.item.id,
              slug: data.item.slug || 'abcde',
            },
          }"
        >
          {{ data.value }}
        </b-link>
      </template>
      <template v-slot:cell(niveau)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          {{ data.value }}
        </span>
        <div v-else>
          <b-form-input
            disabled
            type="number"
            v-model="niveau"
            placeholder="Niveau"
          ></b-form-input>
        </div>
      </template>
      <template #cell(actions)="data">
        <table-actions
          :actions="['delete']"
          :editMode="data.item.hasOwnProperty('editMode')"
          @confirmItem="confirmItem"
          @cancelItem="cancelItem"
          @deleteItem="deleteItem(data.item)"
        ></table-actions>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  components: { TableActions },
  props: {
    items: Array,
    loading: Boolean,
    disabled: Boolean,
    libelle: String,
    patrimoine: String,
  },
  data: () => ({
    sortBy: "name",
    perPage: 4,
    currentPage: 1,
    tabledata: [],
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    niveau: "",
    capacite: "",
    type: "",
    list: [],
    sortable: true,
    technicalCapacity: {
      tableFields: [
        { key: "type", label: "Type capacité", sortable: true },

        { key: "name", label: "Capacité technique", sortable: true },

        {
          key: "niveau",
          label: "Niveau",
          sortable: true,
        },
      ],
    },
  }),

  computed: {
    rows() {
      return this.items ? this.items.length : 0;
    },
    ...mapGetters("capaciteTechnique", ["CAPACITES_TECHNIQUES"]),
    CapaciteList() {
      return this.CAPACITES_TECHNIQUES.map((data) => ({
        text: data.name,
        value: data["@id"],
      }));
    },
    ...mapGetters("application", ["APPLICATIONS"]),
    ...mapGetters("composant", ["COMPOSANTS"]),
    ...mapGetters("composant", ["COMPOSANT"]),
    ...mapGetters("application", ["APPLICATION"]),
    ComposantList() {
      return this.COMPOSANTS.map((data) => ({
        text: data.name,
        value: data.id,
      }));
    },
    ApplicationList() {
      return this.APPLICATIONS.map((data) => ({
        text: data.name,
        value: data["@id"],
      }));
    },
  },
  methods: {
    deleteItem: function (item) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          if (item.type == "Technique") {
            let filter = this.items
              .filter((x) => x["@id"] !== item["@id"] && x.type == "Technique")
              .map((data) => data["@id"]);
            let val = {
              id: this.$route.params.id,
              capacites: filter,
            };
            this.$store.dispatch("cadre/updateCadre", val).then(() => {
              this.$store.dispatch("cadre/fetchCadre", this.$route.params.id);
              Swal.fire("Supprimé!", "", "success");
            });
          }
        }
      });
    },

    capacitedata() {
      if (this.type == "Technique") {
        this.$store
          .dispatch("composant/fetchComposant", this.capacite)
          .then(() => {
            this.niveau = this.COMPOSANT.capaciteTechnique
              ? this.COMPOSANT.capaciteTechnique.niveau
              : null;
          });
      }
    },

    changelist() {
      if (!this.type) {
        this.capacite = "";
        this.list = [];
      } else {
        if (this.type == "Application") {
          this.capacite = "";
          this.list = this.ApplicationList;
        } else {
          this.capacite = "";
          this.list = this.ComposantList;
        }
      }
    },
    addContributerInputs() {
      var data = this.items.some((contribute) => contribute.editMode);
      if (data == false) {
        this.currentPage = 1;
        this.items.unshift({
          editMode: true,
        });
      }
    },
    confirmItem: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.type == "Technique") {
          let item = this.items
            .filter((data) => data.type == "Technique")
            .map((data) => data["@id"]);
          this.$store
            .dispatch("composant/fetchComposant", this.capacite)
            .then(() => {
              item.push(
                this.COMPOSANT.capaciteTechnique
                  ? this.COMPOSANT.capaciteTechnique["@id"]
                  : null
              );
              var data = {
                id: this.$route.params.id,
                capacites: item,
              };
              this.$store.dispatch("cadre/updateCadre", data).then(() => {
                this.$store.dispatch("cadre/fetchCadre", this.$route.params.id);
                Swal.fire({
                  title: "La capacité est bien créée !",
                  type: "success",
                });
              });
            });
        }
      }
    },

    cancelItem: function () {
      if (this.type == "" && this.capacite == "") {
        this.items.splice(0, 1);
      } else {
        this.type = "";
        this.capacite = "";
        this.niveau = "";
      }
    },
  },

  addFunction(item) {
    this.technicalCapacity.tableItems[item.itemIndex].function = item.value;
  },

  created() {
    this.$store.dispatch("capaciteTechnique/fetchAllCapacites");
  },
};
</script>

<style></style>
