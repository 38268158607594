<template>
  <section class="tables">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body p-0">
            <div>
              <div class="d-flex">
                <div class="my-auto d-flex mr-auto">
                  <span class="h4 text-secondary my-auto"
                    >Liste des composants techniques
                  </span>
                  <DropDownCustom
                    class="my-auto ml-1"
                    :items="['Tableau', 'Graphique']"
                    @changeValue="
                      (value) =>
                        this.$store.dispatch(
                          'views/changeComposantTechniqueActiveView',
                          value
                        )
                    "
                    :value="$store.state.views.composantTechniqueActiveView"
                  />
                </div>

                <div class="d-flex justify-content-end my-auto">
                  <CustomAddButton
                    @click="showAddComposant = true"
                    :disabled="disabled"
                    text="Ajouter composant technique"
                    class="my-auto"
                  />
                  <CustomAddButton
                    @click="addItem"
                    :disabled="disabled"
                    text="Créer composant technique"
                    class="ml-1 my-auto"
                  />
                </div>
              </div>
              <div class="col-12 p-0">
                <hr class="mt-1 mb-3 bg-secondary" />
              </div>
              <div class="col-12 d-flex flex-wrap p-0 mb-3">
                <div class="pr-2" style="width: 200px">
                  <b-form-input
                    v-model="searchQuery.name"
                    class="mr-2 w-100"
                    placeholder="Libellé"
                    :disabled="disabled"
                  />
                </div>
                <div class="pr-2" style="width: 200px">
                  <v-select
                    v-model="searchQuery.archictecteTechnique"
                    class="mr-2 w-100"
                    :options="responsables"
                    :reduce="({ value }) => value"
                    placeholder="Architecte technique"
                    label="text"
                    :disabled="disabled"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>
                <div class="pr-2" style="width: 200px">
                  <v-select
                    v-model="searchQuery.status"
                    class="mr-2"
                    :options="['Propose', 'Non valide']"
                    placeholder="Statut"
                    :disabled="disabled"
                  >
                    <template #no-options> Liste vide </template></v-select
                  >
                </div>
              </div>
            </div>
            <div v-if="$store.state.views.composantTechniqueActiveView == 0">
              <b-table
                :items="filteredItems"
                id="table-list"
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :busy="loading"
                show-empty
                bordered
                striped
              >
                <!-- FOR LOADING ANIMATIONS -->
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-2"></b-spinner>
                    <strong>Chargement...</strong>
                  </div>
                </template>
                <!-- SHOW IF TABLE IS EMPTY -->
                <template #empty>
                  <div class="text-center text-gray">Table vide</div>
                </template>
                <template v-slot:cell(name)="data">
                  <div v-if="!data.item.hasOwnProperty('editMode')">
                    <b-link
                      :to="{
                        name: 'show-composant',
                        params: {
                          id: data.item.id,
                          slug: data.item.slug || 'abcde',
                        },
                      }"
                      >{{ data.value }}
                    </b-link>
                  </div>
                  <div v-else>
                    <b-form-input
                      type="text"
                      placeholder="Composant"
                      v-model="newComposant.name"
                      :class="{
                        'is-invalid':
                          $v.newComposant.name.$error &&
                          $v.newComposant.name.$dirty,
                      }"
                    ></b-form-input>
                    <!-- ERROR MESSAGES -->
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newComposant.name.$dirty"
                    >
                      {{
                        !$v.newComposant.name.required
                          ? "Champ obligatoire"
                          : !$v.newComposant.name.minLength
                          ? `Le champ doit contenir au moins ${$v.newComposant.name.$params.minLength.min} caractères.`
                          : ""
                      }}
                    </span>
                  </div>
                </template>
                <template v-slot:cell(capacites)="data">
                  <div
                    v-if="!data.item.hasOwnProperty('editMode')"
                    class="text-center"
                  >
                    <b-badge
                      variant="primary"
                      pill
                      style="
                        cursor: pointer;
                        background-color: #adb5bd;
                        border-color: #adb5bd;
                      "
                      @click="CapaciteComposant(data.item)"
                      >{{ data.value ? data.value.length : 0 }}
                    </b-badge>
                  </div>
                  <div class="text-center" v-else style="">
                    <b-badge
                      style="
                        cursor: pointer;
                        background-color: #adb5bd;
                        border-color: #adb5bd;
                      "
                      pill
                      @click="CapaciteList(data.item)"
                      >{{ listCap.length == 0 ? "+" : listCap.length }}
                    </b-badge>
                  </div>
                </template>
                <template #cell(actions)="data">
                  <table-actions
                    :actions="disabled ? [] : ['transfer', 'delete']"
                    :editMode="data.item.hasOwnProperty('editMode')"
                    @cancelItem="cancelItem"
                    @confirmItem="createItem(data.item.id)"
                    @deleteItem="deleteItem(data.item.id)"
                    @editItem="editItem(data.item)"
                    @transferItem="transferComposant(data.item)"
                  />
                </template>

                <template v-slot:cell(archictecteTechnique)="data">
                  <TableResponsable
                    v-if="!data.item.hasOwnProperty('editMode')"
                    :responsable="data.value ? data.value : ''"
                  />
                  <div v-else>
                    <v-select
                      append-to-body
                      label="text"
                      :reduce="(user) => user.value"
                      :options="responsables"
                      placeholder="Responsable"
                      v-model="newComposant.responsable"
                      style="padding-right: 0 !important"
                      :class="{
                        'is-invalid form-control p-0':
                          $v.newComposant.responsable.$error &&
                          $v.newComposant.responsable.$dirty,
                      }"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                    <!-- ERROR MESSAGES -->
                    <br />
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newComposant.responsable.$dirty"
                    >
                      {{
                        !$v.newComposant.responsable.required
                          ? "Champ obligatoire"
                          : ""
                      }}
                    </span>
                  </div>
                </template>

                <template v-slot:cell(taux)="data">
                  <b-progress max="100" show-progress height="15px">
                    <b-progress-bar
                      :value="data.value"
                      :label="`${data.value}%`"
                      :style="`background-color: ${
                        data.value > 20 && data.value <= 70
                          ? '#f5f245'
                          : data.value > 70
                          ? '#23d92c'
                          : '#f5ba45'
                      }`"
                    ></b-progress-bar>
                  </b-progress>
                </template>

                <template v-slot:cell(applications)="data">
                  <div
                    class="text-center"
                    v-if="!data.item.hasOwnProperty('editMode')"
                  >
                    <b-badge
                      v-if="data.value.length"
                      style="
                        cursor: pointer;
                        background-color: #adb5bd;
                        border-color: #adb5bd;
                      "
                      @click="selectedapp(data.item)"
                      pill
                    >
                      {{ data.value.length }}
                    </b-badge>
                    <b-badge
                      style="
                        cursor: pointer;
                        background-color: #adb5bd;
                        border-color: #adb5bd;
                      "
                      v-else
                      pill
                    >
                      {{ data.value.length }}
                    </b-badge>
                  </div>
                  <div v-else>
                    <multiselect
                      append-to-body
                      multiple
                      label="text"
                      track-by="value"
                      v-model="newComposant.application"
                      :options="applications"
                      placeholder="Choisir les applications"
                    ></multiselect>
                  </div>
                </template>

                <template v-slot:cell(versions)="data">
                  <div
                    v-if="!data.item.hasOwnProperty('editMode')"
                    class="text-center"
                  >
                    <b-badge
                      style="
                        cursor: pointer;
                        background-color: #adb5bd;
                        border-color: #adb5bd;
                      "
                      v-if="data.value.length"
                      @click="selectedversion(data.item)"
                      pill
                    >
                      {{ data.value.length }}
                    </b-badge>
                    <b-badge
                      v-else
                      style="
                        cursor: pointer;
                        background-color: #adb5bd;
                        border-color: #adb5bd;
                      "
                      pill
                    >
                      {{ data.value.length }}
                    </b-badge>
                  </div>
                  <div v-else>
                    <multiselect
                      append-to-body
                      multiple
                      label="text"
                      track-by="value"
                      v-model="newComposant.version"
                      :options="version"
                      placeholder="Choisir les versions"
                    ></multiselect>
                  </div>
                </template>

                <template v-slot:cell(type)="data">
                  <span v-if="!data.item.hasOwnProperty('editMode')">
                    {{ data.value ? data.value : "-" }}
                  </span>
                  <div v-else>
                    <v-select
                      append-to-body
                      :options="['BDO']"
                      placeholder="Type"
                      v-model="newComposant.type"
                      style="padding-right: 0 !important"
                      :class="{
                        'is-invalid form-control p-0':
                          $v.newComposant.type.$error &&
                          $v.newComposant.type.$dirty,
                      }"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                    <br />
                    <!-- ERROR MESSAGES -->
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newComposant.type.$dirty"
                    >
                      {{
                        !$v.newComposant.type.required
                          ? "Champ obligatoire"
                          : ""
                      }}
                    </span>
                  </div>
                </template>

                <template v-slot:cell(editeur)="data">
                  <span v-if="!data.item.hasOwnProperty('editMode')">
                    {{ data.value ? data.value.name : "-" }}
                  </span>
                  <div v-else>
                    <v-select
                      label="text"
                      :reduce="(editeur) => editeur.value"
                      append-to-body
                      :options="editerusList"
                      placeholder="editeur"
                      v-model="newComposant.editeur"
                      style="padding-right: 0 !important"
                      :class="{
                        'is-invalid form-control p-0':
                          $v.newComposant.editeur.$error &&
                          $v.newComposant.editeur.$dirty,
                      }"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                    <br />
                    <!-- ERROR MESSAGES -->
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newComposant.editeur.$dirty"
                    >
                      {{
                        !$v.newComposant.editeur.required
                          ? "Champ obligatoire"
                          : ""
                      }}
                    </span>
                  </div>
                </template>

                <template v-slot:cell(businessLine)="data">
                  <div
                    v-if="!data.item.hasOwnProperty('editMode')"
                    class="text-center"
                  >
                    <b-badge
                      style="
                        cursor: pointer;
                        background-color: #adb5bd;
                        border-color: #adb5bd;
                      "
                      pill
                    >
                      {{ data.value.length }}
                    </b-badge>
                  </div>
                  <div v-else>
                    <multiselect
                      multiple
                      label="text"
                      track-by="value"
                      v-model="newComposant.businessLine"
                      :options="linesList"
                      placeholder="Choisir les ligne métier"
                    ></multiselect>
                  </div>
                </template>

                <template v-slot:cell(status)="data">
                  <span v-if="!data.item.hasOwnProperty('editMode')">
                    {{ data.value }}
                  </span>
                  <div v-else>
                    <v-select
                      append-to-body
                      :options="['Propose', 'Non valide']"
                      placeholder="Status"
                      v-model="newComposant.status"
                      style="padding-right: 0 !important"
                      :class="{
                        'is-invalid form-control p-0':
                          $v.newComposant.status.$error &&
                          $v.newComposant.status.$dirty,
                      }"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                    <br />
                    <!-- ERROR MESSAGES -->
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newComposant.status.$dirty"
                    >
                      {{
                        !$v.newComposant.status.required
                          ? "Champ obligatoire"
                          : ""
                      }}
                    </span>
                  </div>
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="table-list"
                align="right"
              >
              </b-pagination>
            </div>
            <div v-else><GraphicViewComposant :items="filteredItems" /></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showApps" to="modals-xyz-500">
      <modal @close="showApps = !showApps">
        <app-list :items="selectedapplication" :libelle="libelle" />
      </modal>
    </div>

    <div v-if="showVersion" to="modals-xyz-500">
      <modal @close="showVersion = !showVersion">
        <VersionList :composant="selectedComposant" :libelle="libelle" />
      </modal>
    </div>
    <div v-if="ShowCapacite" to="modals-xyz-500">
      <modal @close="ShowCapacite = !ShowCapacite">
        <Capaciteselect
          :libelle="libelle"
          @ConfirmCap="ConfirmCap"
          :selected="Capcite"
          @close="ShowCapacite = !ShowCapacite"
        ></Capaciteselect>
      </modal>
    </div>
    <div v-if="ShowCapaciteComposant" to="modals-xyz-500">
      <modal @close="ShowCapaciteComposant = !ShowCapaciteComposant">
        <CapaciteComposant
          :items="CapaciteComp"
          :libelle="libelle"
        ></CapaciteComposant>
      </modal>
    </div>
    <div v-if="transferCompo" to="modals-xyz-548">
      <modal @close="transferCompo = !transferCompo">
        <TransferComposant
          :selectedComposant="selectedComposant"
          :libelle="libelle"
          @close="transferCompo = !transferCompo"
        />
      </modal>
    </div>
    <div v-if="showAddComposant" to="modals-xyz-548">
      <modal @close="showAddComposant = !showAddComposant">
        <AddComposant
          :patrimoine="COMPOSANT"
          @close="showAddComposant = !showAddComposant"
          :libelle="libelle"
        />
      </modal>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableActions from "@/components/custom/TableActions.vue";
import Swal from "sweetalert2";
import TableResponsable from "@/components/custom/TableResponsable.vue";
import { mapGetters } from "vuex";
import { minLength, required } from "vuelidate/lib/validators";
import Modal from "@/components/custom/Modal.vue";
import VersionList from "./VersionList.vue";
import appList from "./AppList.vue";
import Capaciteselect from "./Capaciteselect.vue";
import { concat } from "lodash";
import CapaciteComposant from "./CapaciteComposant";
import CustomAddButton from "@/components/custom/CustomAddButton.vue";
import DropDownCustom from "@/components/custom/DropDown.vue";
import GraphicViewComposant from "./GraphicViewComposant";
import TransferComposant from "./TransferComposant";
import AddComposant from "./AddComposant.vue";
import { USER_URL, PATRIMOINE_URL } from "@/helpers/constURL";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    CapaciteComposant,
    TableActions,
    TableResponsable,
    Modal,
    VersionList,
    appList,
    Capaciteselect,
    CustomAddButton,
    DropDownCustom,
    GraphicViewComposant,
    TransferComposant,
    AddComposant,
  },
  data: function () {
    return {
      sortBy: "name",
      loading: true,
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      selectedapplication: null,
      ShowCapaciteComposant: false,
      showApps: false,
      selectedComposant: null,
      CapaciteComp: null,
      showVersion: false,
      Capcite: [],
      listCap: [],
      ShowCapacite: false,
      newComposant: {
        responsable: "",
        editeur: "",
        status: "",
        name: "",
        type: "",
      },
      fields: [
        { key: "name", label: "Composant", sortable: true },
        { key: "editeur", label: "Editeur", sortable: true },
        { key: "status", label: "Status", sortable: true },
        {
          key: "type",
          label: "Type Composant",
          sortable: true,
        },
        { key: "capacites", label: "Capacites", sortable: true },
        { key: "taux", label: "Taux de remplissage", sortable: true },
        {
          key: "archictecteTechnique",
          label: "Architect technique",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
      searchQuery: {
        name: "",
        status: "",
        archictecteTechnique: null,
      },
      showGraf: 0,
      transferCompo: false,
      showAddComposant: false,
    };
  },

  validations: {
    newComposant: {
      name: {
        required,
        minLength: minLength(5),
      },
      type: {
        required,
      },
      status: {
        required,
      },

      responsable: {
        required,
      },

      editeur: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.id) {
      this.$store.dispatch("capaciteTechnique/fetchAllCapacites");
      this.$store.dispatch("users/fetchAllResponsables");
      this.$store.dispatch("editeur/fetchAllEditeurs");
      this.$store
        .dispatch("patrimoine/fetchComposants", this.$route.params.id)
        .then(() => {
          this.loading = false;
        });
    } else this.loading = false;
  },
  props: {
    disabled: Boolean,
    libelle: String,
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("capaciteTechnique", ["CAPACITES_TECHNIQUES"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("businessLine", ["BUSINESS_LINES"]),
    // ...mapGetters("composant", ["COMPOSANT"]),
    ...mapGetters("editeur", ["EDITEURS"]),
    ...mapGetters("version", ["VERSIONS"]),
    ...mapGetters("application", ["APPLICATIONS"]),
    ...mapGetters("patrimoine", ["COMPOSANT"]),

    rows() {
      return this.COMPOSANT && this.COMPOSANT.composants
        ? this.COMPOSANT.composants.length
        : 0;
    },
    responsables() {
      var data = this.RESPONSABLES.map((responsable) => ({
        value: responsable.id,
        text: `${responsable.firstname} ${responsable.lastname}`,
      }));
      return data;
    },
    capacitesList() {
      return this.CAPACITES_TECHNIQUES.map((data) => ({
        value: data["@id"],
        text: data.name,
      }));
    },
    usersList() {
      return this.RESPONSABLES.map((user) => ({
        text: `${user.firstname} ${user.lastname}`,
        value: `/api/${USER_URL}/${user.id}`,
      }));
    },
    linesList() {
      return this.BUSINESS_LINES.map((data) => ({
        value: data["@id"],
        text: data.name,
      }));
    },
    editerusList() {
      return this.EDITEURS.map((editeur) => ({
        value: editeur["@id"],
        text: editeur.name,
      }));
    },
    version() {
      return this.VERSIONS.map((editeur) => ({
        value: editeur["@id"],
        text: editeur.name,
      }));
    },
    applications() {
      return this.APPLICATIONS.map((app) => ({
        value: app["@id"],
        text: app.name,
      }));
    },
    filteredItems() {
      return this.COMPOSANT &&
        this.COMPOSANT.composants &&
        this.$route.params.id
        ? this.COMPOSANT.composants
            .filter((composant) =>
              composant.name
                ? composant.name
                    .toLowerCase()
                    .indexOf(this.searchQuery.name.toLowerCase()) > -1
                : true && this.searchQuery.status
                ? composant.status === this.searchQuery.status
                : true
            )
            .filter((composant) =>
              this.searchQuery.archictecteTechnique
                ? composant.archictecteTechnique.id ===
                  this.searchQuery.archictecteTechnique
                : true
            )
            .filter((composant) =>
              this.searchQuery.status
                ? composant.status === this.searchQuery.status
                : true
            )
        : [];
    },
  },
  methods: {
    ConfirmCap(data) {
      this.listCap = data;
    },
    CapaciteComposant(data) {
      if (data.capacites.length) {
        this.CapaciteComp = data;
        this.ShowCapaciteComposant = !this.ShowCapaciteComposant;
      }
    },
    selectedapp(data) {
      this.selectedapplication = data;
      this.showApps = !this.showApps;
    },
    CapaciteList(data) {
      this.ShowCapacite = !this.ShowCapacite;
      var test = this.COMPOSANT.composants.find(
        (contributer) => contributer.Modify == true
      );
      if (test) {
        var push = this.listCap;
        this.Capcite = push;
      } else {
        var pushdata = this.Capcite
          ? concat(
              this.listCap,
              data.capacites ? data.capacites.map((data) => data["@id"]) : []
            )
          : data.capacites
          ? data.capacites.map((data) => data["@id"])
          : [];
        this.Capcite = pushdata;
      }
    },
    selectedversion(data) {
      this.selectedComposant = data;
      this.showVersion = !this.showVersion;
    },
    editItem(data) {
      var test = this.COMPOSANT.composants.find(
        (contributer) => contributer.editMode == true
      );
      if (test) {
        this.$store
          .dispatch("patrimoine/fetchComposants", this.$route.params.id)
          .then(() => {
            this.COMPOSANT.composants = this.COMPOSANT.composants.map(
              (contributer) =>
                contributer.id === data.id
                  ? { ...contributer, editMode: true, Modify: true }
                  : contributer
            );
            this.newComposant.name = data.name;
            this.newComposant.type = data.type;
            this.listCap = data.capacites.map((data) => data["@id"]);
            this.newComposant.status = data.status;
            this.newComposant.editeur = data.editeur["@id"];
            this.newComposant.responsable = data.archictecteTechnique.id;
          });
      } else {
        this.COMPOSANT.composants = this.COMPOSANT.composants.map(
          (contributer) =>
            contributer.id === data.id
              ? { ...contributer, editMode: true, Modify: true }
              : contributer
        );
        this.newComposant.name = data.name;
        this.listCap = data.capacites.map((data) => data["@id"]);
        this.newComposant.type = data.type;
        this.newComposant.status = data.status;
        this.newComposant.editeur = data.editeur["@id"];
        this.newComposant.responsable = data.archictecteTechnique
          ? data.archictecteTechnique.id
          : null;
      }
    },
    addItem() {
      var data = this.COMPOSANT.composants.find(
        (contributer) => contributer.editMode == true
      );
      if (data == null) {
        this.$store.dispatch("views/changeComposantTechniqueActiveView", 0);
        this.currentPage = 1;
        this.COMPOSANT.composants.unshift({ editMode: true });
      }
    },
    cancelItem() {
      if (
        this.newComposant.name == "" &&
        this.newComposant.type == "" &&
        this.newComposant.status == "" &&
        this.newComposant.responsable == "" &&
        this.newComposant.editeur == ""
      ) {
        var data = this.COMPOSANT.composants.find(
          (contributer) => contributer.Modify == true
        );
        if (data == null) {
          this.COMPOSANT.composants.splice(0, 1);
        } else {
          this.listCap = [];
          this.$store.dispatch(
            "patrimoine/fetchComposants",
            this.$route.params.id
          );
        }
      } else {
        this.newComposant.name = "";
        this.newComposant.type = "";
        this.newComposant.status = "";
        this.newComposant.responsable = "";
        this.newComposant.capacite = "";
        this.newComposant.application = "";
        this.newComposant.version = "";
        this.newComposant.businessLine = "";
        this.newComposant.editeur = "";
      }
    },

    createItem(id) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var test = this.COMPOSANT.composants.find(
          (contributer) => contributer.Modify == true
        );
        if (test == null) {
          this.$store
            .dispatch("composant/createComposant", {
              ...this.newComposant,
              capacites: this.listCap ? this.listCap : [],
              avancement: "0",
              taux: 0,
              archictecteTechnique: `api/users/${this.newComposant.responsable}`,
              patrimoine: `/api/${PATRIMOINE_URL}/${this.$route.params.id}`,
              editeur: this.newComposant.editeur,
            })
            .then(() => {
              this.$store
                .dispatch("patrimoine/fetchComposants", this.$route.params.id)
                .then(() => {
                  this.transferCompo = false;
                  Swal.fire(
                    "Le composant technique est bien créé !",
                    "",
                    "success"
                  );
                  this.newComposant.name = "";
                  this.newComposant.type = "";
                  this.newComposant.status = "";
                  this.newComposant.responsable = "";
                  this.listCap = [];
                  this.Capcite = [];
                  this.newComposant.editeur = "";
                });
            });
        } else {
          this.$store
            .dispatch("composant/updateComposant", {
              ...this.newComposant,
              id: id,
              capacites: this.listCap ? this.listCap : [],
              editeur: this.newComposant.editeur,
              archictecteTechnique: `api/users/${this.newComposant.responsable}`,
              patrimoine: `/api/${PATRIMOINE_URL}/${this.$route.params.id}`,
            })
            .then(() => {
              Swal.fire(
                "Le composant technique est mis à jour !",
                "",
                "success"
              );
              this.$store
                .dispatch("patrimoine/fetchComposants", this.$route.params.id)
                .then(() => {
                  this.transferCompo = false;
                  this.newComposant.name = "";
                  this.newComposant.type = "";
                  this.newComposant.status = "";
                  this.newComposant.responsable = "";
                  this.listCap = [];
                  this.Capcite = [];
                  this.newComposant.editeur = "";
                });
            });
        }
      }
    },
    deleteItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("composant/updateComposant", {
              id: id,
              patrimoine: null,
            })
            .then(() => {
              this.$store.dispatch(
                "patrimoine/fetchComposants",
                this.$route.params.id
              );
            });
          Swal.fire("Supprimé!", "", "success");
        }
      });
    },
    onValueChange(value) {
      this.$store
        .dispatch("patrimoine/fetchComposants", this.$route.params.id)
        .then(() => {
          this.showGraf = value;
        });
    },
    transferComposant(composant) {
      this.selectedComposant = composant;

      this.transferCompo = !this.transferCompo;
    },
  },
};
</script>
<style></style>
