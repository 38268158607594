var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row card m-0"},[_c('div',{staticClass:"col-12 p-0"},[_c('div',{staticClass:"col-12 d-flex flex-column"},[(!_vm.items.length)?_c('div',{staticClass:"text-gray d-flex h2 text-center w-100",staticStyle:{"height":"166px"}},[_c('i',{staticClass:"mdi my-auto ml-auto mdi-information-outline my-auto"}),_c('span',{staticClass:"my-auto ml-1 mr-auto"},[_vm._v(" Aucune donnée")])]):_vm._e(),_vm._l((_vm.groupBy(_vm.items, 'capacites[0][@id]')),function(item,index){return _c('div',{key:index},[(item[0].capacites.length)?_c('p',{staticClass:"mt-3 mb-2 h5"},[_vm._v(" "+_vm._s(item[0].capacites[0].name)+" ")]):_c('p',{staticClass:"mt-3 mb-2 h5"},[_vm._v("N/A")]),_c('div',{staticClass:"text-white row p-2 rounded my-2 py-3",staticStyle:{"background":"#f2edf3"}},[(!item.length)?_c('div',{staticClass:"text-gray d-flex h2 text-center w-100",staticStyle:{"height":"166px"}},[_c('i',{staticClass:"mdi my-auto ml-auto mdi-information-outline my-auto"}),_c('span',{staticClass:"my-auto ml-1 mr-auto"},[_vm._v(" Aucune donnée")])]):_vm._e(),_vm._l((item),function(application,appIndex){return _c('div',{key:appIndex,staticClass:"col-12 col-md-3 mb-2"},[_c('div',{staticClass:"bg-gradient-success rounded h-100 py-2 px-4"},[_c('p',{staticClass:"mb-0"},[_c('b-link',{staticStyle:{"text-decoration":"none","color":"white"},attrs:{"to":{
                    name: 'show-application',
                    params: {
                      id: application.id,
                      slug: application.slug || 'abcde',
                    },
                  }}},[_vm._v(" "+_vm._s(application.name))])],1)])])})],2)])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }