<template>
  <div style="margin-top: -60px">
    <div class="row">
      <div class="col-10 col-md-10">
        <p class="h2 m-0 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

          <span style="font-size: 18px" class="font-weight-light"
            >Patrimoine :
          </span>
          <span style="font-size: 22px">{{ libelle }}</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12">
        <h4 class="card-title mt-4 mb-1">Liste des application supportées :</h4>
        <hr />
      </div>
    </div>

    <b-table
      :fields="appFields"
      :items="application.applications"
      id="table-list"
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="loading"
      show-empty
      bordered
      striped
    >
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <template v-slot:cell(taux)="data">
        <b-progress :max="100" height="1rem">
          <b-progress-bar :value="data.value">
            <span> {{ data.value }}% </span>
          </b-progress-bar>
        </b-progress>
      </template>

      <template v-slot:cell(status)="data">
        <b-badge v-if="data.value == 'accepted'" variant="primary">
          {{ data.value }}
        </b-badge>

        <b-badge v-else variant="secondary">
          {{ data.value }}
        </b-badge>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    sortBy: "name",
    perPage: 4,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    loading: true,
    appItems: [
      {
        ref: "Ref 1",
        libelle: "app 1",
        capacite: 60,

        status: "accepted",
        taux: 20,
      },
      {
        ref: "Ref 2",
        libelle: "app 1",
        capacite: 60,

        status: "accepted",

        taux: 20,
      },
      {
        ref: "Ref 3",
        libelle: "app 1",
        capacite: 60,

        status: "rejected",

        taux: 40,
      },
      {
        ref: "Ref 4",
        libelle: "app 1",
        capacite: 60,

        status: "accepted",
        taux: 95,
      },
    ],
    appFields: [
      { key: "ref", label: "Ref SI", sortable: true },
      { key: "libelle", label: "Libellé", sortable: true },
      { key: "capacite", label: "Capacite SI", sortable: true },
      { key: "status", label: "Statut", sortable: true },
      { key: "taux", label: "Taux de remplissage", sortable: true },
    ],
  }),
  created() {
    this.$store.dispatch("version/fetchApplication", this.items.id).then(() => {
      this.loading = false;
    });
  },
  props: {
    items: Object,
    libelle: String,
  },
  computed: {
    ...mapGetters("version", ["APPLICATION"]),
    application() {
      return this.APPLICATION;
    },
    rows() {
      return this.APPLICATION.applications
        ? this.APPLICATION.applications.length
        : 0;
    },
  },
};
</script>

<style></style>
