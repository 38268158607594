<template>
  <section class="tables">
    <div class="row">
      <div class="col-lg-12 px-3 grid-margin stretch-card">
        <div class="card">
          <div class="">
            <div>
              <div class="d-flex">
                <div class="my-auto d-flex mr-auto">
                  <span class="h4 text-secondary mr-auto my-auto"
                    >Liste des portefeuilles cadres d’entreprise
                  </span>
                  <!-- <DropDownCustom
                    class="my-auto ml-1"
                    :items="['Tableau', 'Graphique']"
                    @changeValue="onValueChange"
                  /> -->
                </div>
                <div class="d-flex justify-content-end">
                  <CustomAddButton
                    @click="showAddCadre = true"
                    :disabled="disabled"
                    text="Ajouter cadre d’entreprise"
                    class="my-auto mr-1"
                  />
                  <CustomAddButton
                    @click="addItem"
                    :disabled="disabled"
                    text="Créer cadre d’entreprise"
                    class="my-auto"
                  />
                </div>
              </div>
              <div class="col-12 p-0">
                <hr class="mt-1 mb-3 bg-secondary" />
              </div>
              <div class="col-12 d-flex flex-wrap p-0 mb-3">
                <div class="pr-2" style="width: 200px">
                  <b-form-input
                    v-model="searchQuery.name"
                    class="mr-2 w-100"
                    placeholder="Libellé"
                  />
                </div>
                <div class="pr-2" style="width: 200px">
                  <v-select
                    v-model="searchQuery.po"
                    :options="responsables"
                    :reduce="({ value }) => value"
                    placeholder="Responsable"
                    label="text"
                    style="min-width: max-content"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>
                <div class="pr-2" style="width: 200px">
                  <v-select
                    v-model="searchQuery.status"
                    class="mr-2"
                    :options="['Validé', 'Déployé']"
                    placeholder="Statut"
                    style="min-width: max-content"
                  >
                    <template #no-options> Liste vide </template></v-select
                  >
                </div>
              </div>
            </div>

            <div v-if="!showGraf">
              <b-table
                :items="filteredCadres"
                id="table-list"
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                bordered
                striped
                show-empty
              >
                <!-- FOR LOADING ANIMATIONS -->
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-2"></b-spinner>
                    <strong>Chargement...</strong>
                  </div>
                </template>
                <!-- SHOW IF TABLE IS EMPTY -->
                <template #empty>
                  <div class="text-center text-gray">Table vide</div>
                </template>

                <template v-slot:cell(name)="data">
                  <b-link
                    v-if="!data.item.hasOwnProperty('editMode')"
                    @click="
                      $router.push({
                        name: 'show-principe-ref',
                        params: {
                          id: data.item.id,
                          slug: data.item.slug,
                        },
                      })
                    "
                    >{{ data.value }}
                  </b-link>
                  <div v-else>
                    <b-form-input
                      :class="{
                        'is-invalid':
                          $v.newCadrs.name.$error && $v.newCadrs.name.$dirty,
                      }"
                      v-model="newCadrs.name"
                      placeholder="Cadre"
                      type="text"
                    />
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newCadrs.name.$dirty"
                    >
                      {{
                        !$v.newCadrs.name.required ? "Champ obligatoire" : ""
                      }}
                    </span>
                  </div>
                </template>

                <template v-slot:cell(principe)="data">
                  <b-link
                    v-if="!data.item.hasOwnProperty('editMode')"
                    @click="
                      $router.push({
                        name: 'show-principe-ref',
                        params: {
                          id: data.value.id,
                          slug: data.item.slug
                            ? data.value.slug
                            : '620f6d5321c35',
                        },
                      })
                    "
                    >{{ data.value ? data.value.name : "-" }}
                  </b-link>
                  <div v-else>
                    <v-select
                      :options="principe"
                      appendToBody
                      label="text"
                      :reduce="(principes) => principes.value"
                      v-model="newCadrs.principe"
                      placeholder="Principe appliqué"
                      type="text"
                      style="padding: 0 !important ; min-width: max-content"
                      :class="{
                        'is-invalid form-control p-0':
                          $v.newCadrs.principe.$error &&
                          $v.newCadrs.principe.$dirty,
                      }"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newCadrs.principe.$dirty"
                    >
                      {{
                        !$v.newCadrs.principe.required
                          ? "Champ obligatoire"
                          : ""
                      }}
                    </span>
                  </div>
                </template>
                <template v-slot:cell(responsable)="data">
                  <TableResponsable
                    v-if="!data.item.hasOwnProperty('editMode')"
                    :responsable="data.value"
                    >{
                  </TableResponsable>
                  <div v-else>
                    <v-select
                      append-to-body
                      label="text"
                      :reduce="(user) => user.value"
                      :options="responsables"
                      v-model="newCadrs.responsable"
                      placeholder="Responsable"
                      type="text"
                      style="padding: 0 !important ; min-width: max-content"
                      :class="{
                        'is-invalid form-control p-0':
                          $v.newCadrs.responsable.$error &&
                          $v.newCadrs.responsable.$dirty,
                      }"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newCadrs.responsable.$dirty"
                    >
                      {{
                        !$v.newCadrs.responsable.required
                          ? "Champ obligatoire"
                          : ""
                      }}
                    </span>
                  </div>
                </template>
                <template v-slot:cell(status)="data">
                  <span v-if="!data.item.hasOwnProperty('editMode')">{{
                    data.value ? data.value : "-"
                  }}</span>
                  <div v-else>
                    <v-select
                      append-to-body
                      :options="['En prod', 'En cours', 'Terminé']"
                      v-model="newCadrs.status"
                      placeholder="Statut"
                      type="text"
                      style="padding: 0 !important ; min-width: max-content"
                      :class="{
                        'is-invalid form-control p-0':
                          $v.newCadrs.status.$error &&
                          $v.newCadrs.status.$dirty,
                      }"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newCadrs.status.$dirty"
                    >
                      {{
                        !$v.newCadrs.status.required ? "Champ obligatoire" : ""
                      }}
                    </span>
                  </div>
                </template>

                <template v-slot:cell(type)="data">
                  <span v-if="!data.item.hasOwnProperty('editMode')">{{
                    data.value
                  }}</span>
                  <div v-else>
                    <v-select
                      append-to-body
                      :options="['Pattern', 'Règle', 'Politique']"
                      v-model="newCadrs.type"
                      placeholder="Type"
                      type="text"
                      style="padding: 0 !important ; min-width: max-content"
                      :class="{
                        'is-invalid form-control p-0':
                          $v.newCadrs.type.$error && $v.newCadrs.type.$dirty,
                      }"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newCadrs.type.$dirty"
                    >
                      {{
                        !$v.newCadrs.type.required ? "Champ obligatoire" : ""
                      }}
                    </span>
                  </div>
                </template>

                <template v-slot:cell(capaciteTechnique)="data">
                  <div class="text-center">
                    <b-badge
                      variant="primary"
                      pill
                      style="
                        cursor: pointer;
                        background-color: #adb5bd;
                        border-color: #adb5bd;
                      "
                      @click="capacitelist(data.value, data.item.name)"
                      >{{ data.value.length }}
                    </b-badge>
                  </div>
                </template>

                <template v-slot:cell(systemesApplication)="data">
                  <div class="text-center">
                    <b-badge
                      variant="primary"
                      pill
                      style="
                        cursor: pointer;
                        background-color: #adb5bd;
                        border-color: #adb5bd;
                      "
                      @click="systemeapp(data.value, data.item.name)"
                      >{{ data.value.systemelength || 0 }}
                    </b-badge>
                  </div>
                </template>

                <template #cell(actions)="data">
                  <table-actions
                    :actions="['delete', 'transfer']"
                    :editMode="data.item.hasOwnProperty('editMode')"
                    @deleteItem="deleteItem(data.item.id)"
                    @cancelItem="cancelItem"
                    @confirmItem="addCadrs(data.item)"
                    @transferItem="transferCadreFunction(data.item)"
                  />
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="table-list"
                align="right"
              >
              </b-pagination>
            </div>
            <div v-else><GraphicViewCadre :items="filteredCadres" /></div>
          </div>
        </div>
      </div>
      <div v-if="showfile" to="modals-xyz-548">
        <modal @close="showfile = !showfile">
          <Filelist :items="data" :libelle="libelle" />
        </modal>
      </div>
      <div v-if="showlist" to="modals-xyz-548">
        <modal @close="showlist = !showlist">
          <CapaciteList
            :items="capacite"
            :libelle="name"
            :patrimoine="libelle"
          />
        </modal>
      </div>
      <div v-if="showSysteme" to="modals-xyz-548">
        <modal @close="showSysteme = !showSysteme">
          <SystemeList :items="systeme" :libelle="name" :patrimoine="libelle" />
        </modal>
      </div>
      <div v-if="showAddCadre" to="modals-xyz-548">
        <modal @close="showAddCadre = !showAddCadre">
          <AddCadre
            :patrimoine="CADRES"
            @close="showAddCadre = !showAddCadre"
            :libelle="libelle"
          />
        </modal>
      </div>
      <div v-if="transferCadre" to="modals-xyz-548">
        <modal @close="transferCadre = !transferCadre">
          <TransferCadre
            :selectedCadre="selectedCadre"
            :libelle="libelle"
            @close="transferCadre = !transferCadre"
          />
        </modal>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableActions from "../../../../components/custom/TableActions.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import { IMG_URL } from "@/helpers/services/api";
import TableResponsable from "../../../../components/custom/TableResponsable";
import Filelist from "./Filelist.vue";
import Modal from "@/components/custom/Modal.vue";
import CapaciteList from "./CapaciteListCadrs.vue";
import SystemeList from "./SystemeList.vue";
// import DropDownCustom from "@/components/custom/DropDown.vue";
import CustomAddButton from "@/components/custom/CustomAddButton.vue";
import AddCadre from "./AddCadre.vue";
import TransferCadre from "./TransferCadre";
import GraphicViewCadre from "./GraphicViewCadre";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    TableResponsable,
    Filelist,
    Modal,
    TableActions,
    CapaciteList,
    SystemeList,
    // DropDownCustom,
    CustomAddButton,
    AddCadre,
    TransferCadre,
    GraphicViewCadre,
  },
  data: function () {
    return {
      sortBy: "",
      perPage: 5,
      IMG_URL,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      data: null,
      showfile: false,
      showSysteme: false,
      showlist: false,
      systeme: "",
      capacite: "",
      id: null,
      newCadrs: {
        name: "",
        applications: "",
        status: "",
        type: "",
        file: "",
        capaciteTechnique: "",
        principe: "",
        typeSysteme: "",
        systemesApplication: "",
        typeRefcard: "",
        responsable: "",
        contributeurs: "",
      },
      fields: [
        { key: "name", label: "Libellé cadre", sortable: true },
        { key: "type", label: "Type", sortable: true },
        { key: "principe", label: "Principe appliqué", sortable: true },
        { key: "responsable", label: "Responsable", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        {
          key: "systemesApplication",
          label: "Systèmes d'application",
          sortable: true,
        },
        {
          key: "capaciteTechnique",
          label: "Capacité technique",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
      showGraf: 0,
      searchQuery: {
        name: "",
        status: "",
        po: null,
      },
      tableItems: [],
      showAddCadre: false,
      transferCadre: false,
    };
  },
  validations: {
    newCadrs: {
      name: {
        required,
      },

      status: {
        required,
      },
      type: {
        required,
      },

      principe: {
        required,
      },

      responsable: {
        required,
      },
    },
  },
  computed: {
    rows() {
      return this.filteredCadres && this.$route.params.id
        ? this.filteredCadres.length
        : 0;
    },
    ...mapGetters("capaciteTechnique", ["CAPACITES_TECHNIQUES"]),
    ...mapGetters("application", ["APPLICATIONS"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("principe", ["PRINCIPES"]),
    ...mapGetters("cadre", ["CADRE_ENTREPRISES"]),
    ...mapGetters("cadre", ["CADRE_ENTREPRISE"]),
    ...mapGetters("patrimoine", ["CADRES"]),

    cadre() {
      var item = this.filteredCadres.find(
        (contributer) => contributer.Modify == true
      );
      if (item) {
        return this.CADRE_ENTREPRISES.map((data) => ({
          text: data.name,
          value: data.id,
        })).filter(
          (data) =>
            !this.filteredCadres
              .filter((cadre) => cadre.id !== this.id)
              .find((application) => application.id === data.value)
        );
      } else {
        return this.CADRE_ENTREPRISES.map((data) => ({
          text: data.name,
          value: data.id,
        })).filter(
          (data) =>
            !this.filteredCadres.find(
              (application) => application.id === data.value
            )
        );
      }
    },
    principe() {
      return this.PRINCIPES.map((principe) => ({
        value: `/api/referentiel/principes/${principe.id}`,
        text: principe.name,
      }));
    },
    responsables() {
      return this.RESPONSABLES.map((responsable) => ({
        value: `/api/users/${responsable.id}`,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    capaciteRatechements() {
      return this.CAPACITES_TECHNIQUES.map((data) => ({
        text: data.name,
        value: data["@id"],
      }));
    },
    applicationsList() {
      return this.APPLICATIONS.map((data) => ({
        text: data.name,
        value: data.id,
      }));
    },
    filteredCadres() {
      return this.$route.params.id
        ? this.tableItems
            .filter((cadre) =>
              cadre.name
                ? cadre.name
                    .toLowerCase()
                    .includes(this.searchQuery.name.toLowerCase())
                : true
            )
            .filter((cadre) =>
              this.searchQuery.status
                ? cadre.status === this.searchQuery.status
                : true
            )
            .filter((cadre) =>
              this.searchQuery.po
                ? cadre.responsable["@id"] === this.searchQuery.po
                : true
            )
        : [];
    },
  },
  props: {
    // items: Array,
    disabled: Boolean,
    libelle: String,
  },
  methods: {
    onValueChange(value) {
      this.showGraf = value;
    },
    display(id) {
      this.data = id;
      this.showfile = !this.showfile;
    },
    systemeapp(item, name) {
      if (item.systeme.length > 0) {
        this.systeme = item.systeme;
        this.name = name;
        this.showSysteme = !this.showSysteme;
      }
    },
    capacitelist(data, name) {
      if (data.length) {
        this.capacite = data;
        this.name = name;
        this.showlist = !this.showlist;
      }
    },
    cancelItem() {
      if (
        this.newCadrs.name == "" &&
        this.newCadrs.type == "" &&
        this.newCadrs.status == "" &&
        this.newCadrs.responsable == null &&
        this.newCadrs.principe == null
      ) {
        var item = this.tableItems.find(
          (contributer) => contributer.Modify == true
        );
        if (item == null) {
          this.tableItems.splice(0, 1);
        } else {
          this.$store.dispatch("patrimoine/fetchRef", this.$route.params.id);
        }
      } else {
        this.newCadrs.name = "";
        this.newCadrs.type = "";
        this.newCadrs.principe = null;
        this.newCadrs.responsable = null;
        this.newCadrs.status = "";
      }
    },

    addCadrs() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("cadre/createCadre", {
            patrimoine: `/api/referentiel/patrimoines/${this.$route.params.id}`,
            name: this.newCadrs.name,
            type: this.newCadrs.type,
            principe: this.newCadrs.principe,
            responsable: this.newCadrs.responsable,
            status: this.newCadrs.status,
          })
          .then(() => {
            this.$store
              .dispatch("patrimoine/fetchRef", this.$route.params.id)
              .then(() => {
                Swal.fire(
                  "Le cadre d’entreprise est bien créé !",
                  "",
                  "success"
                );
                this.newCadrs.name = "";
                this.newCadrs.type = "";
                this.newCadrs.principe = null;
                this.newCadrs.responsable = null;
                this.newCadrs.status = "";
              });
          });
      }
    },
    addItem() {
      var data = this.tableItems.find(
        (contributer) => contributer.editMode == true
      );
      if (data == null) {
        this.currentPage = 1;
        this.tableItems.unshift({ editMode: true });
      }
    },
    deleteItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          var da = this.tableItems
            .filter((item) => item.id !== id)
            .map((data) => data["@id"]);
          var cadr = {
            id: this.$route.params.id,
            cadres: da,
          };
          this.$store
            .dispatch("patrimoine/updatePatrimoines", cadr)
            .then(() => {
              Swal.fire("Supprimé!", "", "success");
              this.$store.dispatch(
                "patrimoine/fetchRef",
                this.$route.params.id
              );
            });
        }
      });
    },
    transferCadreFunction(cadre) {
      this.selectedCadre = cadre;

      this.transferCadre = !this.transferCadre;
    },
  },
  created() {
    if (this.$route.params.id) {
      this.$store.dispatch("capaciteTechnique/fetchAllCapacites");
      this.$store.dispatch("application/fetchAllApplications");
      this.$store.dispatch("users/fetchAllResponsables");
      this.$store.dispatch("principe/fetchAllPrincipes");
      this.$store.dispatch("cadre/fetchAllCadres");
      this.$store.dispatch("patrimoine/fetchRef", this.$route.params.id);
    }
  },
  watch: {
    CADRES() {
      this.tableItems = this.CADRES.cadres.map((data) => ({
        ...data,
        systemesApplication: {
          systemelength:
            data.applications.length +
            (data.composants ? data.composants.length : 0),
        },
        capaciteTechnique: data.capacites.map((data) => ({
          ...data,
          type: "Technique",
          name: data.name,
          niveau: data.niveau ? data.niveau : "-",
        })),
      }));
    },
  },
};
</script>
