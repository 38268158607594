<template>
  <div style="margin-top: -50px">
    <div class="row" style="margin-top: -56px">
      <div class="col-10">
        <p class="h2 m-0 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

          <span style="font-size: 18px" class="font-weight-light"
            >Patrimoine :
          </span>
          <span style="font-size: 22px">{{ libelle }}</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h4 class="card-title mt-4 mb-1">
          Cadre d'entreprise :
          <span style="font-size: 22px">{{ items.name }}</span>
        </h4>

        <hr />
      </div>
    </div>

    <b-table
      :fields="fields"
      :items="items.fileCadres"
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-empty
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>

      <template v-slot:cell(filename)="data">
        <a :href="IMG_URL + data.item.fileUrl" target="_blank">{{
          data.value ? data.value : "-"
        }}</a>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import { IMG_URL } from "@/helpers/services/api";

export default {
  data() {
    return {
      IMG_URL,
      sortBy: "name",
      perPage: 4,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [{ key: "filename", label: "Fichier" }],
    };
  },

  created() {},
  computed: {
    rows() {
      return this.items.fileCadres.length;
    },
  },
  methods: {},
  props: {
    items: Array,
    libelle: String,
  },
};
</script>

<style>
</style>